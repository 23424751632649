import dayjs from 'dayjs';
import { Conditions, globalDateTimeFormat, globalDateFormat } from '@/constants/';
import { DQNewStageEvent, TableColumn, User } from '@/typings';
import { FilterType, CarrierStatus, StageEventType } from '@/typings/enums';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { VistaEvent, Weight } from '@/services/model/vista';
import Filters from '@/scripts/componentFilter';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

// default table settings
export const NonSortableColumns = ['primaryMode', 'totalGrossWeight'];

const DQ_NOT_FOUND_ENTERED_VALUE = '-';
const DQ_NOT_FOUND_DISPLAYED_VALUE = 'Not found';

const dqTeamStageEventEdit = (stageEventType: StageEventType) => ({
  isValid: (value: string) => {
    if (value === DQ_NOT_FOUND_ENTERED_VALUE) {
      return true;
    } else {
      return parseISO8601UTC(value) !== null;
    }
  },
  changedFieldValue: (oldValue, newValue) => {
    // should only be called if isValid === true
    if (newValue === DQ_NOT_FOUND_ENTERED_VALUE) {
      return { type: stageEventType };
    } else {
      return { type: stageEventType, payload: parseISO8601UTC(newValue) };
    }
  },
  toDqApi: (newValue: string): DQNewStageEvent => {
    if (newValue === DQ_NOT_FOUND_ENTERED_VALUE) {
      return { type: stageEventType };
    } else {
      if (Filters.validate(newValue)) {
        return { type: stageEventType, date: newValue };
      } else {
        return { type: stageEventType, date: undefined };
      }
    }
  }
});

const stageEventPath = function (value?: VistaEvent | null, isEditable?: boolean) {
  if (value) {
    return value.payload ? dateTzAndFormatDQ(value.payload, isEditable) : DQ_NOT_FOUND_DISPLAYED_VALUE;
  } else {
    return '-';
  }
};

// Table columns declaration:
//  field: string - name (id) of the column
//  visible: boolean - default visible status
//  property: string - optional - declares property that contains the data when it is different than field (happens when multiple columns from the same field, need different ID)
//  order: number - default order in table
//  width: number - default width of columns in pixels
//  sortStr: string - optional, sets a specific value for sorting query
//  path: function or false - function to process backend response and display on table, or false for default (plain value)
//  dqTeamEdit: optional object for editing cells in table.
//              specifies functions for checking validity of a value, editing the internal state, and sending a request to API.

export const TableColumns: TableColumn[] = [
  {
    field: 'deliveryNumber',
    visible: true,
    order: 1,
    width: 150,
    path: false
  },
  {
    field: 'shipmentNumber',
    visible: true,
    order: 2,
    width: 170,
    sortStr: 'mainDirectLegShipmentNumber',
    path: false
  },
  {
    field: 'origin',
    visible: true,
    order: 3,
    width: 200,
    sortStr: 'origin.name',
    path: (v) => (v ? v.name + ' - ' + v.plantCode : '-')
  },
  {
    field: 'originCountry',
    property: 'origin',
    visible: true,
    order: 4,
    width: 150,
    sortStr: 'origin.countryCode',
    path: (v) => (v ? v.countryCode : '-')
  },
  {
    field: 'destination',
    visible: true,
    order: 5,
    width: 270,
    sortStr: 'destination.name',
    path: (v) => (v ? v.name : '-')
  },
  {
    field: 'destinationCountry',
    property: 'destination',
    visible: true,
    order: 6,
    width: 150,
    sortStr: 'destination.countryCode',
    path: (v) => (v ? v.countryCode : '-')
  },
  {
    field: 'state',
    visible: true,
    order: 7,
    width: 130,
    path: (v) => (v ? Conditions[FilterType.STATE].optionsCheckbox.find((f) => f.id === v)!.name : '-')
  },
  {
    field: 'lateness',
    visible: true,
    order: 8,
    width: 150,
    sortStr: 'lateness.amount',
    path: (v) => latenessCalc(v)
  },
  {
    field: 'reasonCode',
    property: 'lateness',
    visible: true,
    order: 9,
    width: 150,
    path: (lateness) => (lateness ? lateness.reasonCode : '-')
  },
  {
    field: 'reason',
    property: 'lateness',
    visible: true,
    order: 10,
    width: 150,
    path: (lateness) => (lateness ? lateness.reason : '-')
  },
  {
    field: 'confirmedDeliveryDate',
    visible: true,
    order: 11,
    width: 130,
    path: (v) => {
      return v ? dateTzAndFormat(v) : '-';
    }
  },
  {
    field: 'shipmentStart',
    visible: true,
    order: 12,
    width: 130,
    path: (v) => {
      return v ? dateTzAndFormat(v) : '-';
    }
  },
  {
    field: 'etdPortOfLoading',
    visible: true,
    order: 13,
    width: 170,
    sortStr: 'etdPortOfLoading.payload',
    path: stageEventPath,
    dqTeamEdit: dqTeamStageEventEdit(StageEventType.ETD)
  },
  {
    field: 'atdPortOfLoading',
    visible: true,
    order: 14,
    width: 170,
    sortStr: 'atdPortOfLoading.payload',
    path: stageEventPath,
    dqTeamEdit: dqTeamStageEventEdit(StageEventType.ATD)
  },
  {
    field: 'etaPortOfDischarge',
    visible: true,
    order: 15,
    width: 170,
    sortStr: 'etaPortOfDischarge.payload',
    path: stageEventPath,
    dqTeamEdit: dqTeamStageEventEdit(StageEventType.ETA)
  },
  {
    field: 'ataPortOfDischarge',
    visible: true,
    order: 16,
    width: 170,
    sortStr: 'ataPortOfDischarge.payload',
    path: stageEventPath,
    dqTeamEdit: dqTeamStageEventEdit(StageEventType.ATA)
  },
  {
    field: 'incotermCode',
    property: 'incoterm',
    visible: true,
    order: 17,
    width: 170,
    sortStr: 'incoterm.code',
    path: (v) => (v && v.code ? v.code : '-')
  },
  {
    field: 'incotermLocation',
    property: 'incoterm',
    visible: true,
    order: 18,
    width: 170,
    sortStr: 'incoterm.location',
    path: (v) => (v && v.location ? v.location : '-')
  },
  {
    field: 'containerId',
    visible: true,
    order: 19,
    width: 170,
    path: (v) => (v ? v.replaceAll(/^0+/g, '') : '-')
  },
  {
    field: 'billOfLadingNumber',
    visible: true,
    order: 20,
    width: 170,
    path: false
  },
  {
    field: 'SP',
    property: 'partners',
    visible: true,
    order: 21,
    width: 130,
    path: (v) => {
      return v && v.SP ? v.SP : '-';
    }
  },
  {
    field: 'mainDirectLegCarrier',
    visible: true,
    order: 22,
    width: 170,
    sortStr: 'mainDirectLegCarrier.name',
    path: false
  },
  {
    field: 'preLegCarrier',
    visible: true,
    order: 23,
    width: 170,
    sortStr: 'preLegCarrier.name',
    path: false
  },
  {
    field: 'bookingNumber',
    visible: true,
    order: 24,
    width: 170,
    path: false
  },
  {
    field: 'vesselIMO',
    property: 'vessel',
    visible: true,
    order: 25,
    width: 170,
    sortStr: 'vessel.imo',
    path: (v) => (v && v.imo ? v.imo : '-')
  },
  {
    field: 'vesselName',
    property: 'vessel',
    visible: true,
    order: 26,
    width: 170,
    sortStr: 'vessel.name',
    path: (v) => (v && v.name ? v.name : '-')
  },
  {
    field: 'voyageId',
    visible: true,
    order: 27,
    width: 170,
    path: false
  },
  {
    field: 'airwayBillNumber',
    visible: true,
    order: 28,
    width: 170,
    path: false
  },
  {
    field: 'masterAirWaybill',
    visible: true,
    order: 29,
    width: 170,
    path: false
  },
  {
    field: 'flightNumber',
    visible: true,
    order: 30,
    width: 170,
    path: false
  },
  {
    field: 'materialNumber',
    property: 'materials',
    visible: true,
    order: 31,
    width: 170,
    sortStr: 'materials.number',
    path: (v) => (v && v[0] ? v.map((m) => Number(m.number)) : '-')
  },
  {
    field: 'materialDescription',
    property: 'materials',
    visible: true,
    order: 32,
    width: 170,
    sortStr: 'materials.description',
    path: (v) => (v && v[0] && v[0].description ? v.map((m) => m.description) : '-')
  },
  {
    field: 'sbus',
    visible: true,
    order: 33,
    width: 170,
    path: (v) => (v ? v.join('; ') : '-')
  },
  {
    field: 'primaryMode',
    visible: true,
    order: 34,
    width: 150,
    path: false
  },
  {
    field: 'specialProcessingIndicator',
    visible: true,
    order: 35,
    width: 150,
    path: false
  },
  {
    field: 'equipmentType',
    visible: true,
    order: 36,
    width: 150,
    path: false
  },
  {
    field: 'totalGrossWeight',
    visible: true,
    order: 37,
    width: 150,
    path: (v) => weightFormat(v)
  },
  {
    field: 'purchaseOrderNumber',
    visible: true,
    order: 38,
    width: 150,
    path: false
  },
  {
    field: 'salesOrderNumber',
    visible: true,
    order: 39,
    width: 150,
    path: false
  },
  {
    field: 'TB',
    property: 'partners',
    visible: false,
    order: 40,
    width: 130,
    path: (v) => {
      return v && v.TB ? v.TB : '-';
    }
  },
  {
    field: 'WE',
    property: 'partners',
    visible: false,
    order: 41,
    width: 130,
    path: (v) => {
      return v && v.WE ? v.WE : '-';
    }
  },
  {
    field: '9A',
    property: 'partners',
    visible: false,
    order: 42,
    width: 130,
    path: (v) => {
      return v && v['9A'] ? v['9A'] : '-';
    }
  },
  {
    field: '9W',
    property: 'partners',
    visible: false,
    order: 43,
    width: 130,
    path: (v) => {
      return v && v['9W'] ? v['9W'] : '-';
    }
  },
  {
    field: '9O',
    property: 'partners',
    visible: false,
    order: 44,
    width: 130,
    path: (v) => {
      return v && v['9O'] ? v['9O'] : '-';
    }
  },
  {
    field: 'AS',
    property: 'partners',
    visible: false,
    order: 45,
    width: 130,
    path: (v) => {
      return v && v.AS ? v.AS : '-';
    }
  },
  {
    field: 'AM',
    property: 'partners',
    visible: false,
    order: 46,
    width: 130,
    path: (v) => {
      return v && v.AM ? v.AM : '-';
    }
  },
  {
    field: 'Z5',
    property: 'partners',
    visible: false,
    order: 47,
    width: 130,
    path: (v) => {
      return v && v.Z5 ? v.Z5 : '-';
    }
  },
  {
    field: 'Z6',
    property: 'partners',
    visible: false,
    order: 48,
    width: 130,
    path: (v) => {
      return v && v.Z6 ? v.Z6 : '-';
    }
  },
  {
    field: 'VE',
    property: 'partners',
    visible: false,
    order: 49,
    width: 130,
    path: (v) => {
      return v && v.VE ? v.VE : '-';
    }
  },
  {
    field: 'CO',
    property: 'partners',
    visible: false,
    order: 50,
    width: 130,
    path: (v) => {
      return v && v.CO ? v.CO : '-';
    }
  },
  {
    field: 'TS',
    property: 'partners',
    visible: false,
    order: 51,
    width: 130,
    path: (v) => {
      return v && v.TS ? v.TS : '-';
    }
  },
  {
    field: 'NY',
    property: 'partners',
    visible: false,
    order: 52,
    width: 130,
    path: (v) => {
      return v && v.NY ? v.NY : '-';
    }
  },
  {
    field: 'TD',
    property: 'partners',
    visible: false,
    order: 53,
    width: 130,
    path: (v) => {
      return v && v.TD ? v.TD : '-';
    }
  },
  {
    field: 'TT',
    property: 'partners',
    visible: false,
    order: 54,
    width: 130,
    path: (v) => {
      return v && v.TT ? v.TT : '-';
    }
  },
  {
    field: 'SI',
    property: 'partners',
    visible: false,
    order: 55,
    width: 130,
    path: (v) => {
      return v && v.SI ? v.SI : '-';
    }
  },
  {
    field: 'unloadingPoint',
    visible: true,
    order: 56,
    width: 170,
    path: false
  },
  {
    field: 'meansOfTransportId',
    visible: true,
    order: 57,
    width: 170,
    path: false
  }
];

const latenessCalc = (lateness: { amount: number; status: string }) => {
  if (!lateness || lateness.amount === null || !lateness.status) {
    // anything can come null as usual
    return '-';
  }

  const amount = Math.abs(lateness.amount);
  const unit = amount > 23 ? Math.floor(amount / 24) + ' Days' : amount + ' Hours';

  if (lateness.status === 'EARLY') {
    return unit + ' early';
  } else if (lateness.status === 'LATE') {
    return unit + ' late';
  }
  return CarrierStatus[lateness.status]; // on time
};

export const weightFormat = (weightData) => {
  if (!weightData || !weightData.weight || !weightData.unit) {
    return '-';
  }
  return `${weightData.weight} ${weightData.unit}`;
};

const dateTzAndFormat = (date: string) => {
  return Filters.toLocalDate(date, true);
};

const dateTzAndFormatDQ = (date: string, isEditable: boolean | undefined) => {
  return Filters.applyOnlyDateTimeFormat(date, true, isEditable);
};

export function parseISO8601UTC(value: string): string | null {
  if (!value) {
    return null;
  }

  const formats = [globalDateTimeFormat, globalDateFormat];
  for (const format of formats) {
    const parse = dayjs.utc(value, format, true);
    if (parse.isValid()) {
      return parse.format();
    }
  }

  return null;
}
